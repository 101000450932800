import { Menu } from '@mui/icons-material'
import { Avatar, Box, IconButton } from '@mui/material'
import React, { useCallback, useState } from 'react'
import { useSignOut } from 'src/modules/auth/hooks'
import { Color } from 'src/styles/color'
import { Drawer } from './Drawer'
import { useGetMe } from 'src/modules/users/hooks'

export interface HeaderProps {
  hideDrawer?: boolean
}
export const Header = ({ hideDrawer = false }: HeaderProps) => {
  const { handleSignOut: signout } = useSignOut()
  const [open, setOpen] = useState(false)
  const { data } = useGetMe()

  const handleSignOut = useCallback(() => {
    const isDebug = process.env.NEXT_PUBLIC_APP_ENV !== 'prod'
    if (isDebug) {
      const isConfirm = window.confirm(`[デバッグ機能(本番では出ません)]\n\nサインアウトしますか？`)
      if (isConfirm) {
        signout()
      }
    }
  }, [signout])

  return (
    <>
      {/* drawer */}
      {!hideDrawer && <Drawer open={open} onClose={() => setOpen(false)} />}

      <Box
        sx={{
          height: 56,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${Color.blackAlpha200}`,
          position: 'relative',
          pr: '8px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!hideDrawer && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <IconButton onClick={() => setOpen(v => !v)}>
                <Menu />
              </IconButton>
            </Box>
          )}

          <Box sx={{ width: 1, display: 'flex', justifyContent: hideDrawer ? 'center' : 'left', px: '48px' }}>
            <img src="/assets/logo.png" height="28px" width="auto" onClick={handleSignOut} />
          </Box>
        </Box>

        <Box>
          <Avatar sx={{ width: '32px', height: '32px' }} src={data?.user?.profileImageUrls?.small} />
        </Box>
      </Box>
    </>
  )
}
