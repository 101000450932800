import { useCallback } from 'react'
import { useGrpcClient } from '../grpc/hooks'
import useSWR from 'swr'
import { SWRKey } from '../swr/key'
import { auth } from '@singcolor/firebase-client'
import jwtDecode from 'jwt-decode'
import { GetUserProfileV2Response, GetUserV1Response } from '@singcolor/grpc-client/src/singcolor-proto/ts/users_v1_pb'
import { GetServerRequestedTimeResponse } from '@singcolor/grpc-client/src/singcolor-proto/ts/debug_pb'

export const useGetMeProfile = () => {
  const { userClient } = useGrpcClient()

  const func = useCallback(async () => {
    const token = await auth.currentUser?.getIdToken(true)
    if (!token) return
    const { user_id: userId } = jwtDecode<{ user_id: string }>(token)
    const data = (await userClient.getUserProfileV2({ userId })) as GetUserProfileV2Response.AsObject
    return data
  }, [userClient])

  return useSWR(SWRKey.getMeProfile(), func)
}

export const useGetMe = () => {
  const { userClient } = useGrpcClient()

  const func = useCallback(async () => {
    const token = await auth.currentUser?.getIdToken(true)
    if (!token) return
    const { user_id: userId } = jwtDecode<{ user_id: string }>(token)
    const data = (await userClient.getUser({ userId })) as GetUserV1Response.AsObject
    return data
  }, [userClient])

  return useSWR(SWRKey.getMe(), func)
}

export const useGetRequestedTime = () => {
  const { debugClient } = useGrpcClient()
  const func = useCallback(async () => {
    const data = (await debugClient.getServerRequestedTime()) as GetServerRequestedTimeResponse.AsObject
    return data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debugClient])

  return useSWR(SWRKey.getMeRequestedTime(), func)
}
